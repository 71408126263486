import React, { useEffect, useState } from 'react'
import styles from './index.module.scss'
import cm from 'classnames'
import { createRoot } from 'react-dom/client'

const Index = props => {
  const { content = '', onClose } = props
  const [closed, setClosed] = useState(false)
  const messageClass = cm(styles.message, styles.messagePhone, {
    [styles.closed]: closed
  })
  const close = () => {
    setClosed(true)
    setTimeout(() => {
      onClose()
    }, 300)
  }
  useEffect(() => {
    const t = setTimeout(close, 3000)
    return () => {
      t && clearTimeout(t)
    }
  }, [])
  return (
    <div className={messageClass}>
      <div className={styles['message-content']}>{content}</div>
    </div>
  )
}

const _message = options => {
  const { content, type } = options
  const messageRoot = document.querySelector('#message-root')
  const _dom = document.createElement('div')
  messageRoot.innerHTML = ''
  messageRoot.append(_dom)

  const handleClose = () => {
    root.unmount()
    _dom.remove()
  }
  const root = createRoot(_dom)
  root.render(<Index content={content} type={type} onClose={handleClose} />)
}

export default _message
