import styles from './index.module.scss'
import cm from 'classnames'
import Image from '@c/image'

const Page = props => {
  const { data, active } = props

  const changeSwiper2 = (index)=>{
    // console.log(index,"===changeSwiper  222=====")
  }

  return (
    <div className={styles.pagination}>
      {/* <div className={styles.items}>
        {data.map((item, index) => {
          return <span key={item.id} className={cm(styles.item, active === index ? styles.itemActive : '')}  
          onClick={changeSwiper2(index)}  >   
          <img  src={ item.picImg }  />
           </span>
        })}
      </div> */}
    </div>
  )
}

export default Page
