import styles from './index.module.scss'
import cm from 'classnames'
import { useRouter } from 'next/router'

const Index = props => {
  const { active, url, className } = props
  const router = useRouter()
  const toRecommend = () => {
    router.push(`/${url}`)
  }
  const toAll = () => {
    router.push(`/${url}/all/all/all`)
  }
  return (
    <div className={cm(styles.switch, styles.switchPhone, className)}>
      <div className={cm(styles.item, active === 1 ? styles.active1 : '')} onClick={toRecommend}>
        {/* <img loading="lazy" src="/api/images/recommend" alt="推荐" /> */}
        <span>推荐</span>
      </div>
      <div className={cm(styles.item, active === 2 ? styles.active2 : '')} onClick={toAll}>
        {/* <img loading="lazy" src="/api/images/all" alt="全部" /> */}
        <span>全部</span>
      </div>
    </div>
  )
}

export default Index
