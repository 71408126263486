import omit from 'lodash-es/omit'
import { useState, useRef, useEffect } from 'react'

/**
 * 图片组件封装
 * initSrc:预载图片
 * errorSrc:加载失败填充图片
 * 懒加载图片IntersectionObserver
 * */
const Index = props => {
  const { initSrc = '/api/images/init', errorSrc = '/api/images/default', src } = props
  const [imgSrc, setSrc] = useState(initSrc)
  const imgRef = useRef(null)
  const newProps = omit(props, ['src', 'onError', 'initSrc', 'errorSrc'])
  useEffect(() => {
    const imgCurrent = imgRef.current
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting && imgCurrent) {
          setSrc(src || errorSrc)
          observer.unobserve(imgCurrent)
        }
      })
    })

    observer.observe(imgCurrent)

    return () => {
      if (imgCurrent) {
        observer.unobserve(imgCurrent)
      }
    }
  }, [src, errorSrc])

  return <img alt={newProps.alt || '图片'} ref={imgRef} src={imgSrc} {...newProps} onError={() => setSrc(errorSrc)} />
}

export default Index
