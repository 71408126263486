import cm from 'classnames'

const Index = ({ children, className, needHidden, top = 0 }) => {
  return (
    <div className={cm('responsive', className)} style={{ marginTop: top }}>
      <div className="responsive-left"></div>
      <div className={cm('responsive-center', needHidden ? 'overflow-hidden' : '')}>{children}</div>
      <div className="responsive-right"></div>
    </div>
  )
}

export default Index
